import '~/app/init.mjs';

import { Button } from '@headlessui/react';
import { setup } from 'goober';
import React, { lazy, useCallback } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Route as WouterRoute, Switch, useLocation } from 'wouter-preact';

import { IS_DEV } from '~/app/constants.mjs';
import type { Route } from '~/app/routes.mjs';
import { routes } from '~/app/routes.mjs';
import { cssBase } from '~/app/styles.mjs';
import { extractRouteParams, isRouteMatch } from '~/utils/router-utils.mjs';

const appRoutes = routes.filter((i) => (i as Route).component) as Array<Route>;
const Modals = lazy(() => import('~/components/Modals.js'));
const Fallback = lazy(() => import('~/components/Fallback.js'));

setup(React.createElement);

if (IS_DEV)
  new EventSource('/esbuild').addEventListener('change', () =>
    location.reload()
  );

function App() {
  const [location, navigate] = useLocation();
  let route, params: Record<string, string>;
  for (const i of routes) {
    if (!isRouteMatch(i.url, location)) continue;
    route = i;
    params = extractRouteParams(i.url, location);
    break;
  }
  const handleNavigateHomepage = useCallback(() => {
    if (location === '/') return;
    navigate('/');
  }, [location, navigate]);
  if (route) {
    route
      .fetch?.()
      .then((promise) => promise.default(params))
      .catch(() => {});
    (route as Route).preload?.();
  }
  routes
    .find((i) => i.url === '*')
    ?.fetch?.()
    .then((promise) => promise.default())
    .catch(() => {});
  return (
    <>
      <div className={cssBase()}>
        <header className="py-2 px-4 lg:block flex justify-center">
          <Button
            className="fugaz-one-regular text-white opacity-50"
            as="button"
            onClick={handleNavigateHomepage}
          >
            ROYALE TACTICS
          </Button>
        </header>
        <main>
          {/* @ts-ignore */}
          <Switch>
            {appRoutes.map((route, idx) => (
              // @ts-ignore
              <WouterRoute key={idx} path={route.url}>
                <React.Suspense fallback={null}>
                  {React.createElement(React.lazy(route.component))}
                </React.Suspense>
              </WouterRoute>
            ))}
            {/* @ts-ignore */}
            <WouterRoute>
              <React.Suspense fallback={null}>
                <Fallback />
              </React.Suspense>
            </WouterRoute>
          </Switch>
        </main>
      </div>
      <React.Suspense fallback={null}>
        <Modals />
      </React.Suspense>
    </>
  );
}

const container: HTMLElement | null = document.getElementById('root');
if (container) ReactDOM.createRoot(container).render(<App />);
